<template>
  <b-card>
    <div slot="header">
      <strong>Editar Grupo</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Grupos' }">Listar Grupo</router-link></p>
    <form v-on:submit.prevent="salvaGrupo">
      <div class="row">

        <div class="col-lg-4">
          <div class="form-group">
              <label name="mnemonico"><strong>Mnemônico</strong></label>
              <input type="text" class="form-control" v-model="grupo.grp_mnemonico" id="mnemonico" required>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="form-group">
              <label name="descricao"><strong>Descrição</strong></label>
              <input type="text" class="form-control" v-model="grupo.grp_descricao" id="descricao" required>
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col-lg-4">
          
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="dataCriacao"><strong>Tipo</strong></label>
              <select class="form-control" v-model="grupo.gtipo_ID">
                <option value="">Selecione</option>
                <option value="1">Usuário do Sistema</option>
                <option value="2">Área ou Célula de Atuação</option>
              </select>
          </div>
        </div>

        <div class="col-lg-2">
          <div class="form-group">
              <label name="descricao"><strong>Ativo</strong></label><br>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': grupo.grp_ativo }"
                  @click="grupo.grp_ativo = true">Ativo</button>
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': !grupo.grp_ativo }"
                  @click="grupo.grp_ativo = false">Inativo</button>
              </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
              <label name="descricao"><strong>Coordenador de Area</strong></label><br>

              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': grupo.grp_coord_areas }"
                  @click="grupo.grp_coord_areas = true">Sim</button>
                <button type="button" class="btn btn-outline-primary" v-bind:class="{ 'active': !grupo.grp_coord_areas }"
                  @click="grupo.grp_coord_areas = false">Não</button>
              </div>
          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">Salvar</button>

    </form>
  </b-card>
</template>

<script>
export default {
  data () {
    return {
      grupo: {
        grp_mnemonico: '',
        grp_descricao: '',
        grp_ativo: 0,
        gtipo_ID: 1,
        grp_coord_areas: 1,
        grp_ID: 0
      }
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.grupo.grp_ID = this.$route.params.id
        this.carregarGrupo()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    carregarGrupo: function () {
      this.campos('gp_Grupo', 'grp_ID = ' + this.grupo.grp_ID).then(
        (response) => {
          this.grupo = response
          console.log(this.grupo)
        },
        (response) => {}
      )
    },
    salvaGrupo: function () {
      this.atualizar('gp_Grupo', this.grupo, this.grupo.grp_ID).then(
        (response) => {
          this.$router.push('/admin/grupos/listar')
        },
        (response) => {}
      )
    }
  }
}
</script>

<style>

</style>
